// src/components/dashboard/tabs/DashboardAPITab.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Tooltip,
    CircularProgress,
    Snackbar,
    Alert,
    Card,
    CardContent,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box
} from '@mui/material';
import {
    Code,
    Plus,
    Eye,
    EyeOff,
    Copy,
    RefreshCw,
    Trash2,
    Check,
    ExternalLink
} from 'lucide-react';
import {
    fetchApiClients,
    createApiClient,
    regenerateApiClientSecret,
    deleteApiClient
} from '../../../services/apiService';

const DashboardAPITab = ({ token, setError }) => {
    const { t } = useTranslation();

    // State for API clients
    const [apiClients, setApiClients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // State for dialogs
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [newClientName, setNewClientName] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    // State for secret visibility
    const [visibleSecrets, setVisibleSecrets] = useState({});

    // State for copy confirmation
    const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
    const [copiedText, setCopiedText] = useState('');

    // State for operations
    const [isCreating, setIsCreating] = useState(false);
    const [isRegenerating, setIsRegenerating] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);


    // Load API clients on mount
    useEffect(() => {
        loadApiClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    // Function to load API clients
    const loadApiClients = async () => {
        setIsLoading(true);
        try {
            const data = await fetchApiClients(token);
            setApiClients(data);
        } catch (err) {
            setError && setError(t('dashboard.api.error.loading_clients'));
            console.error('Error loading API clients:', err);
        } finally {
            setIsLoading(false);
        }
    };

    // Handler for creating a new API client
    const handleCreateClient = async () => {
        if (!newClientName.trim()) {
            return;
        }

        setIsCreating(true);
        try {
            const newClient = await createApiClient(token, newClientName);
            setApiClients(prev => [...prev, newClient]);
            setCreateDialogOpen(false);
            setNewClientName('');

            // Automatically show the secret for the new client
            setVisibleSecrets(prev => ({
                ...prev,
                [newClient.id]: true
            }));
        } catch (err) {
            setError && setError(t('dashboard.api.error.creating_client'));
            console.error('Error creating API client:', err);
        } finally {
            setIsCreating(false);
        }
    };

    // Handler for regenerating client secret
    const handleRegenerateSecret = async (clientId) => {
        setIsRegenerating(clientId);
        try {
            const updatedClient = await regenerateApiClientSecret(token, clientId);
            setApiClients(prev =>
                prev.map(client =>
                    client.id === clientId ? updatedClient : client
                )
            );

            // Automatically show the new secret
            setVisibleSecrets(prev => ({
                ...prev,
                [clientId]: true
            }));
        } catch (err) {
            setError && setError(t('dashboard.api.error.regenerating_secret'));
            console.error('Error regenerating API client secret:', err);
        } finally {
            setIsRegenerating(null);
        }
    };

    // Handler for deleting a client
    const handleDeleteClient = async () => {
        if (!selectedClient) return;

        setIsDeleting(true);
        try {
            await deleteApiClient(token, selectedClient.id);
            setApiClients(prev => prev.filter(client => client.id !== selectedClient.id));
            setDeleteDialogOpen(false);
            setSelectedClient(null);
        } catch (err) {
            setError && setError(t('dashboard.api.error.deleting_client'));
            console.error('Error deleting API client:', err);
        } finally {
            setIsDeleting(false);
        }
    };

    // Handler for toggling secret visibility
    const toggleSecretVisibility = (clientId) => {
        setVisibleSecrets(prev => ({
            ...prev,
            [clientId]: !prev[clientId]
        }));
    };

    // Handler for copying text to clipboard
    const handleCopyToClipboard = (text, label) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedText(label);
            setCopySnackbarOpen(true);
        });
    };

    return (
        <div className="space-y-8">
            {/* Header with title and create button */}
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                    <Code className="text-indigo-500 w-6 h-6" />
                    <Typography variant="h5">{t('dashboard.api.title')}</Typography>
                </div>
                <Button
                    variant="contained"
                    startIcon={<Plus className="w-4 h-4" />}
                    onClick={() => setCreateDialogOpen(true)}
                >
                    {t('dashboard.api.create_key')}
                </Button>
            </div>

            {/* API Keys section */}
            <Card>
                <CardContent>
                    <Typography variant="h6" className="mb-4">
                        {t('dashboard.api.keys')}
                    </Typography>

                    {isLoading ? (
                        <div className="flex justify-center my-8">
                            <CircularProgress />
                        </div>
                    ) : apiClients.length === 0 ? (
                        <div className="text-center my-8 text-gray-500">
                            <Typography>{t('dashboard.api.no_keys')}</Typography>
                            <Button
                                variant="outlined"
                                className="mt-4"
                                onClick={() => setCreateDialogOpen(true)}
                            >
                                {t('dashboard.api.create_first_key')}
                            </Button>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            {apiClients.map(client => (
                                <Box key={client.id} className="p-4 bg-gray-50 rounded-lg">
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <Typography variant="subtitle1" className="font-medium">
                                                {client.name}
                                            </Typography>
                                            <Typography variant="body2" className="text-gray-500">
                                                {t('dashboard.api.created_at')}: {new Date(client.created_at).toLocaleDateString()}
                                            </Typography>
                                            <div className="mt-2">
                                                <Chip
                                                    label={client.is_active ? t('dashboard.api.status.active') : t('dashboard.api.status.inactive')}
                                                    color={client.is_active ? "success" : "default"}
                                                    size="small"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Tooltip title={t('dashboard.api.delete')}>
                                                <IconButton
                                                    color="error"
                                                    onClick={() => {
                                                        setSelectedClient(client);
                                                        setDeleteDialogOpen(true);
                                                    }}
                                                >
                                                    <Trash2 className="w-5 h-5" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <Divider className="my-3" />

                                    <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div>
                                            <Typography variant="body2" className="font-medium mb-1">
                                                {t('dashboard.api.client_id')}
                                            </Typography>
                                            <div className="flex items-center gap-2">
                                                <div className="bg-gray-200 py-2 px-3 rounded font-mono text-sm flex-grow overflow-x-auto">
                                                    {client.client_id}
                                                </div>
                                                <Tooltip title={t('dashboard.api.copy')}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleCopyToClipboard(client.client_id, t('dashboard.api.client_id'))}
                                                    >
                                                        <Copy className="w-4 h-4" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="flex items-center justify-between">
                                                <Typography variant="body2" className="font-medium mb-1">
                                                    {t('dashboard.api.client_secret')}
                                                </Typography>
                                                <Tooltip title={t('dashboard.api.regenerate')}>
                                                    <IconButton
                                                        size="small"
                                                        color="primary"
                                                        disabled={isRegenerating === client.id}
                                                        onClick={() => handleRegenerateSecret(client.id)}
                                                    >
                                                        {isRegenerating === client.id ? (
                                                            <CircularProgress size={16} />
                                                        ) : (
                                                            <RefreshCw className="w-4 h-4" />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <div className="bg-gray-200 py-2 px-3 rounded font-mono text-sm flex-grow overflow-x-auto">
                                                    {visibleSecrets[client.id]
                                                        ? client.client_secret
                                                        : client.client_secret.replace(/./g, '*')}
                                                </div>
                                                <Tooltip title={visibleSecrets[client.id] ? t('dashboard.api.hide') : t('dashboard.api.show')}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => toggleSecretVisibility(client.id)}
                                                    >
                                                        {visibleSecrets[client.id] ? (
                                                            <EyeOff className="w-4 h-4" />
                                                        ) : (
                                                            <Eye className="w-4 h-4" />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t('dashboard.api.copy')}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleCopyToClipboard(client.client_secret, t('dashboard.api.client_secret'))}
                                                    >
                                                        <Copy className="w-4 h-4" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            ))}
                        </div>
                    )}
                </CardContent>
            </Card>

            {/* API Usage Documentation */}
            <Card>
                <CardContent>
                    <Typography variant="h6" className="mb-4">
                        {t('dashboard.api.documentation.title')}
                    </Typography>

                    <div className="space-y-6">
                        {/* Usage Examples */}
                        <div>
                            <Typography variant="subtitle1" className="mb-2">
                                {t('dashboard.api.documentation.usage_examples')}
                            </Typography>
                            <div className="bg-gray-900 text-gray-100 p-4 rounded-lg overflow-x-auto">
                                <pre className="text-sm font-mono">
{`# Example: Authentication
curl -X POST \\
  https://dvakota.com/client-api/v1/auth/token/ \\
  -H 'Content-Type: application/json' \\
  -d '{
    "client_id": "your_client_id",
    "client_secret": "your_client_secret"
  }'

# Response:
# {
#   "access": "your_jwt_access_token",
#   "refresh": "your_jwt_refresh_token"
# }

# Example: List sandboxes
curl -X GET \\
  https://dvakota.com/client-api/v1/sandboxes/ \\
  -H 'Authorization: Bearer your_jwt_access_token'`}
                                </pre>
                            </div>
                        </div>

                        {/* Available Endpoints */}
                        <div>
                            <Typography variant="subtitle1" className="mb-2">
                                {t('dashboard.api.documentation.available_endpoints')}
                            </Typography>
                            <TableContainer component={Paper} className="shadow-none border">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('dashboard.api.documentation.method')}</TableCell>
                                            <TableCell>{t('dashboard.api.documentation.endpoint')}</TableCell>
                                            <TableCell>{t('dashboard.api.documentation.description')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Chip
                                                    label="POST"
                                                    size="small"
                                                    className="bg-blue-100 text-blue-800"
                                                />
                                            </TableCell>
                                            <TableCell className="font-mono">/client-api/v1/auth/token/</TableCell>
                                            <TableCell>{t('dashboard.api.documentation.auth_token')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Chip
                                                    label="POST"
                                                    size="small"
                                                    className="bg-blue-100 text-blue-800"
                                                />
                                            </TableCell>
                                            <TableCell className="font-mono">/client-api/v1/auth/token/refresh/</TableCell>
                                            <TableCell>{t('dashboard.api.documentation.refresh_token')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Chip
                                                    label="GET"
                                                    size="small"
                                                    className="bg-green-100 text-green-800"
                                                />
                                            </TableCell>
                                            <TableCell className="font-mono">/client-api/v1/sandboxes/</TableCell>
                                            <TableCell>{t('dashboard.api.documentation.list_sandboxes')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Chip
                                                    label="GET"
                                                    size="small"
                                                    className="bg-green-100 text-green-800"
                                                />
                                            </TableCell>
                                            <TableCell className="font-mono">/client-api/v1/sandboxes/{'{id}'}/ </TableCell>
                                            <TableCell>{t('dashboard.api.documentation.sandbox_details')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Chip
                                                    label="POST"
                                                    size="small"
                                                    className="bg-blue-100 text-blue-800"
                                                />
                                            </TableCell>
                                            <TableCell className="font-mono">/client-api/v1/sandbox/process/start/</TableCell>
                                            <TableCell>{t('dashboard.api.documentation.start_process')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Chip
                                                    label="GET"
                                                    size="small"
                                                    className="bg-green-100 text-green-800"
                                                />
                                            </TableCell>
                                            <TableCell className="font-mono">/client-api/v1/tutors/</TableCell>
                                            <TableCell>{t('dashboard.api.documentation.list_tutors')}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box className="mt-2 text-right">
                                <Button
                                    variant="text"
                                    component="a"
                                    href="/client-api/schema/"
                                    target="_blank"
                                    endIcon={<ExternalLink className="w-4 h-4" />}
                                >
                                    {t('dashboard.api.documentation.view_full_docs')}
                                </Button>
                            </Box>
                        </div>

                        {/* API Limits */}
                        <div>
                            <Typography variant="subtitle1" className="mb-2">
                                {t('dashboard.api.documentation.api_limits')}
                            </Typography>
                            <Typography variant="body2">
                                {t('dashboard.api.documentation.rate_limit_description')}
                            </Typography>
                            <div className="mt-2">
                                <Typography variant="body2" className="flex items-center gap-2">
                                    <Check className="w-4 h-4 text-green-500" />
                                    {t('dashboard.api.documentation.standard_limit', { count: 60 })}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Create API Client Dialog */}
            <Dialog
                open={createDialogOpen}
                onClose={() => !isCreating && setCreateDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>{t('dashboard.api.create_key_dialog.title')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('dashboard.api.create_key_dialog.name')}
                        fullWidth
                        value={newClientName}
                        onChange={(e) => setNewClientName(e.target.value)}
                        disabled={isCreating}
                    />
                    <Typography variant="body2" className="mt-2 text-gray-500">
                        {t('dashboard.api.create_key_dialog.description')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCreateDialogOpen(false)} disabled={isCreating}>
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={handleCreateClient}
                        variant="contained"
                        disabled={!newClientName.trim() || isCreating}
                        startIcon={isCreating ? <CircularProgress size={16} /> : null}
                    >
                        {t('common.create')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => !isDeleting && setDeleteDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle className="text-red-600">
                    {t('dashboard.api.delete_dialog.title')}
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {t('dashboard.api.delete_dialog.confirmation', { name: selectedClient?.name })}
                    </Typography>
                    <Typography variant="body2" className="mt-2 text-red-500 font-medium">
                        {t('dashboard.api.delete_dialog.warning')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} disabled={isDeleting}>
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={handleDeleteClient}
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        startIcon={isDeleting ? <CircularProgress size={16} /> : null}
                    >
                        {t('common.delete')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Copy Snackbar */}
            <Snackbar
                open={copySnackbarOpen}
                autoHideDuration={3000}
                onClose={() => setCopySnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setCopySnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {t('dashboard.api.copy_success', { field: copiedText })}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default DashboardAPITab;