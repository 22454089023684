import axios from 'axios';
import config from '../config';
import i18n from '../i18n';

// Конфигурация Axios с базовым URL
const api = axios.create({
  baseURL: config.apiUrl,
});

// Клиентский API
const clientApi = axios.create({
  baseURL: config.clientApiUrl,
});

// Аутентификация и пользователи
export const login = async (username, password, captchaToken) => {
  const response = await api.post('/users/login', {
    username,
    password,
    captchaToken
  });
  return response.data;
};

export const register = async (username, email, password, captchaToken) => {
  const response = await api.post('/users/register', {
    username,
    email,
    password,
    captchaToken
  });
  return response.data;
};

export const createUserProfile = async (profileData) => {
  try {
    const response = await api.post(`/users/profile/create/`,
        profileData,
        {
          headers: {
            'Content-Type': 'application/json'
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user profile:", error);
    throw error;
  }
};

export const getUserInfo = async (token) => {
  const response = await api.get('/users/user-info', {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return response.data;
};

export const fetchUserProfile = async (token, userId) => {
  try {
    const response = await api.get(`/users/profile/${userId}/`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user profiles:", error);
    throw error;
  }
};

export const add_tester = async (username, email, fullAdditionalInfo) => {
  const response = await api.post('/users/add-tester/', {
    name: username,
    email: email,
    add_information: fullAdditionalInfo,
  });
  return response.data;
};

// Курсы
export const fetchCourses = async (params = {}) => {
  try {
    let url = '/courses/courses?';
    if (params.search) url += `search=${params.search}&`;
    if (params.category) url += `category=${params.category}&`;
    if (params.minPrice) url += `min_price=${params.minPrice}&`;
    if (params.maxPrice) url += `max_price=${params.maxPrice}&`;
    if (params.ordering) url += `ordering=${params.ordering}&`;
    if (params.page) url += `page=${params.page}&`;
    if (params.pageSize) url += `page_size=${params.pageSize}&`;

    const response = await api.get(url);
    return {
      results: response.data.results || [],
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous
    };
  } catch (error) {
    console.error("Error fetching courses:", error);
    return { results: [], count: 0, next: null, previous: null };
  }
};

export const fetchCourseDetails = async (courseId) => {
  try {
    const response = await api.get(`/courses/courses/${courseId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching course details:", error);
    throw error;
  }
};

export const fetchCourseCategories = async () => {
  try {
    const response = await api.get('/courses/categories');
    return response.data;
  } catch (error) {
    console.error("Error fetching course categories:", error);
    return [];
  }
};

// Зачисления
export const enrollInCourse = async (token, courseId) => {
  try {
    const response = await api.post(`/courses/courses/${courseId}/enroll`,
        {},
        { headers: { Authorization: `Token ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error enrolling in course:", error);
    throw error;
  }
};

export const fetchEnrollments = async (token) => {
  try {
    const response = await api.get('/courses/enrollments/list', {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching enrollments:", error);
    throw error;
  }
};

// Уроки и задания
export const fetchLessonDetails = async (token, courseId, lessonId) => {
  try {
    const response = await api.get(`/courses/lessons/${lessonId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching lesson details:", error);
    throw error;
  }
};

export const fetchLessonAssignments = async (token, lessonId) => {
  try {
    const response = await api.get(`/courses/lessons/${lessonId}/assignments`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching lesson assignments:", error);
    return [];
  }
};

export const getAssignmentDetails = async (token, assignmentId) => {
  try {
    const response = await api.get(`/courses/assignments/${assignmentId}`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching assignment details:", error);
    throw error;
  }
};

export const getAssignmentStatus = async (token, assignmentId) => {
  try {
    const response = await api.get(`/courses/assignments/${assignmentId}/status`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching assignment status:", error);
    return null;
  }
};

export const submitAssignment = async (token, assignmentId, data) => {
  try {
    const response = await api.post('/courses/submissions', {
      assignment: assignmentId,
      ...data
    }, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting assignment:", error);
    throw error;
  }
};

export const completeLesson = async (token, lessonId) => {
  try {
    const response = await api.post(`/courses/lessons/${lessonId}/complete`, {}, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error completing lesson:", error);
    throw error;
  }
};

export const getStudentEnvironmentStatus = async (token, environmentId) => {
  try {
    const response = await api.get(`/courses/student-environments/${environmentId}/`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error getting environment status:", error);
    throw error;
  }
};

// Рейтинги и отзывы
export const fetchCourseReviews = async (courseId) => {
  try {
    const response = await api.get(`/courses/reviews?course_id=${courseId}`);
    // Добавим запрос рейтингов для каждого отзыва
    const reviews = response.data;
    const ratings = await fetchCourseRatings(courseId);

    // Соединяем отзывы с рейтингами
    return reviews.map(review => ({
      ...review,
      rating: ratings.find(r => r.user === review.user.id)?.rating || 0
    }));
  } catch (error) {
    console.error("Error fetching course reviews:", error);
    return [];
  }
};

export const fetchRecommendedCourses = async (token) => {
  try {
    const response = await api.get('/courses/recommendations', {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching recommended courses:", error);
    return [];
  }
};

// Песочница
export const startCamundaProcess = async (token, prompt, userId, route, sandbox) => {
  try {
    const currentLanguage = i18n.language;
    const sandbox_id = parseInt(sandbox, 10);

    if (!prompt) {
      throw new Error('Prompt is required');
    }

    const data = {
      user_id: userId,
      sandbox: sandbox_id,
      prompt: prompt,
      language: currentLanguage,
      route: route,
      process_type: 'create_services'
    };

    const response = await api.post(
        '/sandbox/start-process',
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error starting Camunda process:", error);
    throw error;
  }
};

export const getAssistantResponse = async (token, data) => {
  const response = await api.post('/sandbox/assistant-response', data, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return response.data;
};

export const updateSandboxName = async (token, sandboxId, newName) => {
  try {
    const response = await api.patch(
        `/sandbox/sandbox/${sandboxId}/`,
        { name: newName },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating sandbox name:", error);
    throw error;
  }
};

export const fetchActiveSandboxes = async (token, userId, role = 'all') => {
  try {
    let url = '/sandbox/sandbox/?archive=False';

    switch (role) {
      default:
        break;
      case 'owner':
        url += `&owner=${userId}`;
        break;
      case 'coworker':
        url += `&coworkers=${userId}`;
        break;
      case 'all':
        url += `&user=${userId}`;
        break;
    }

    const response = await api.get(url, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching sandboxes:", error);
    return [];
  }
};
export const fetchInstances = async (token, sandboxId) => {
  try {
    const response = await api.get(`/sandbox/instance/?sandbox=${sandboxId}&archive=False`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching instances:", error);
    return [];
  }
};

export const getProcessStage = async (token, ProcessId) => {
  try {
    const response = await api.get(`/sandbox/process/?id=${ProcessId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching process stage:", error);
    return null;
  }
};

export const fetchSandboxComponents = async (token, sandboxId) => {
  try {
    const response = await api.get(`/sandbox/component/?archive=False&sandbox=${sandboxId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching sandbox components:", error);
    return [];
  }
};

export const fetchSandboxComponentsRelations = async (token, sandboxId, componentIds) => {
  try {
    const promises = componentIds.map(id =>
        api.get(`/sandbox/relation/?sandbox=${sandboxId}&from_component=${id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
    );
    const responses = await Promise.all(promises);
    return responses.flatMap(response => response.data);
  } catch (error) {
    console.error("Error fetching sandbox relations:", error);
    return [];
  }
};

export const executeSQLQuery = async (token, componentId, query) => {
  try {
    const response = await api.post(
        `/sandbox/component/${componentId}/execute-sql/`,
        { query },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error executing SQL query:", error);
    throw error;
  }
};

export const getAssignmentFeedback = async (token, assignmentId) => {
  try {
    const response = await api.get(`/courses/assignments/${assignmentId}/feedback/`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error getting assignment feedback:', error);
    throw error;
  }
};

export const searchUsers = async (token, searchTerm) => {
  try {
    const response = await api.get(`/users/search/?query=${searchTerm}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error searching users:", error);
    throw error;
  }
};

export const updateSandboxCoworkers = async (token, sandboxId, coworkerIds) => {
  try {
    const response = await api.patch(
        `/sandbox/sandbox/${sandboxId}/`,
        { coworkers: coworkerIds },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating sandbox coworkers:", error);
    throw error;
  }
};

export const createEnvironmentTemplate = async (token, sandboxId) => {
  const response = await api.post(
      '/sandbox/environment-templates/create_from_sandbox/',
      { sandbox_id: sandboxId },
      { headers: { Authorization: `Token ${token}` } }
  );
  return response.data;
};

export const fetchUserAchievements = async (token) => {
  try {
    const response = await api.get('/users/achievements', {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user achievements:", error);
    return [];
  }
};

export const fetchUserBadges = async (token) => {
  try {
    const response = await api.get('/users/badges', {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user badges:", error);
    return [];
  }
};

export const updateUserProfile = async (token, profileId, profileData) => {
  try {
    const response = await api.patch(
        `/users/profile/${profileId}/`,
        profileData,
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
};

export const fetchEnvironmentTemplates = async (token) => {
  try {
    const response = await api.get('/sandbox/environment-templates/', {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching environment templates:", error);
    return [];
  }
};

export const deployTemplate = async (token, templateId, assignmentId = null) => {
  const data = assignmentId ? { assignment_id: assignmentId } : {};
  const response = await api.post(
      `/sandbox/environment-templates/${templateId}/deploy/`,
      data,
      { headers: { Authorization: `Token ${token}` } }
  );
  return response.data;
};

export const fetchComponentTypes = async (token) => {
  try {
    const response = await api.get('/sandbox/component-type/', {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching component types:", error);
    return [];
  }
};

// Получение инстанса по process_id
export const fetchInstanceByProcessId = async (token, processId) => {
  try {
    const response = await api.get('/sandbox/instance/', {
      params: { process: processId },
      headers: { Authorization: `Token ${token}` }
    });
    return response.data[0]; // Возвращаем первый инстанс
  } catch (error) {
    console.error("Error fetching instance by process ID:", error);
    throw error;
  }
};

// Обновление архитектуры инстанса
export const updateInstanceArchitecture = async (token, instanceId, architecture) => {
  try {
    const response = await api.patch(
        `/sandbox/instance/${instanceId}/`,
        { architecture },
        { headers: { Authorization: `Token ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating instance architecture:", error);
    throw error;
  }
};


// Создание компонента
export const createSandboxComponent = async (token, componentData) => {
  try {
    const response = await api.post(
        '/sandbox/component/',
        componentData,
        { headers: { Authorization: `Token ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating sandbox component:", error);
    throw error;
  }
};

export const createCourse = async (token, courseData) => {
  const response = await api.post('/courses/create', courseData, {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const updateCourse = async (token, courseId, courseData) => {
  const response = await api.patch(`/courses/${courseId}`, courseData, {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

// Module management
export const createModule = async (token, moduleData) => {
  const response = await api.post('/courses/modules', moduleData, {
    headers: { Authorization: `Token ${token}` }
  });
  return response.data;
};

export const updateModule = async (token, moduleId, moduleData) => {
  const response = await api.patch(`/courses/modules/${moduleId}`, moduleData, {
    headers: { Authorization: `Token ${token}` }
  });
  return response.data;
};

export const deleteModule = async (token, moduleId) => {
  await api.delete(`/modules/${moduleId}`, {
    headers: { Authorization: `Token ${token}` }
  });
};

// Lesson management
export const createLesson = async (token, lessonData) => {
  const response = await api.post('/courses/lessons', lessonData, {
    headers: { Authorization: `Token ${token}` }
  });
  return response.data;
};

export const updateLesson = async (token, lessonId, lessonData) => {
  const response = await api.patch(`/courses/lessons/${lessonId}`, lessonData, {
    headers: { Authorization: `Token ${token}` }
  });
  return response.data;
};

export const deleteLesson = async (token, lessonId) => {
  await api.delete(`/courses/lessons/${lessonId}`, {
    headers: { Authorization: `Token ${token}` }
  });
};

// Assignment management
export const createAssignment = async (token, assignmentData) => {
  const response = await api.post('/courses/assignments', assignmentData, {
    headers: { Authorization: `Token ${token}` }
  });
  return response.data;
};

export const updateAssignment = async (token, assignmentId, assignmentData) => {
  const response = await api.patch(`/courses/assignments/${assignmentId}`, assignmentData, {
    headers: { Authorization: `Token ${token}` }
  });
  return response.data;
};

export const deleteAssignment = async (token, assignmentId) => {
  await api.delete(`/assignments/${assignmentId}`, {
    headers: { Authorization: `Token ${token}` }
  });
};

export const submitCourseReview = async (token, courseId, reviewData) => {
  try {
    // Сначала отправляем рейтинг
    await api.post('/courses/ratings', {
      course: courseId,
      rating: reviewData.rating
    }, {
      headers: { Authorization: `Token ${token}` }
    });

    // Затем отправляем отзыв
    const response = await api.post('/courses/reviews', {
      course: courseId,
      content: reviewData.content
    }, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting course review:", error);
    throw error;
  }
};

export const unenrollFromCourse = async (token, courseId) => {
  try {
    const response = await api.post(`/courses/courses/${courseId}/unenroll`, {}, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error unenrolling from course:", error);
    throw error;
  }
};

export const fetchCourseComments = async (token, courseId) => {
  try {
    const response = await api.get(`/courses/comments`, {
      params: {
        content_type: 'course',
        object_id: courseId
      },
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching course comments:", error);
    throw error;
  }
};

export const createCourseComment = async (token, courseId, content) => {
  try {
    const response = await api.post('/courses/comments/create', {
      content_type: 'course',
      object_id: courseId,
      content
    }, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error creating course comment:", error);
    throw error;
  }
};

export const fetchCourseRatings = async (courseId) => {
  try {
    const response = await api.get(`/courses/ratings?course_id=${courseId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching course ratings:", error);
    return [];
  }
};

// Get template access list
export const getTemplateAccessList = async (token, templateId) => {
  try {
    const response = await api.get(`/sandbox/environment-templates/${templateId}/access_list/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching template access list:", error);
    throw error;
  }
};

// Add users or groups to template access
export const addTemplateAccess = async (token, templateId, userIds = [], groupIds = []) => {
  try {
    const response = await api.post(
        `/sandbox/environment-templates/${templateId}/access/`,
        { users: userIds, groups: groupIds },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding template access:", error);
    throw error;
  }
};

// Remove template access
export const removeTemplateAccess = async (token, templateId, accessId) => {
  try {
    const response = await api.delete(
        `/sandbox/environment-templates/${templateId}/access/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          data: { access_id: accessId },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error removing template access:", error);
    throw error;
  }
};

// Delete a template
export const deleteTemplate = async (token, templateId) => {
  try {
    const response = await api.delete(`/sandbox/environment-templates/${templateId}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting template:", error);
    throw error;
  }
};

// Search for groups
export const searchGroups = async (token, searchTerm) => {
  try {
    const response = await api.get(`/courses/groups?search=${searchTerm}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error searching groups:", error);
    throw error;
  }
};

// API Client management
export const fetchApiClients = async (token) => {
  try {
    const response = await clientApi.get('/clients/', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching API clients:", error);
    throw error;
  }
};

export const createApiClient = async (token, name) => {
  try {
    const response = await clientApi.post('/clients/',
        { name },
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating API client:", error);
    throw error;
  }
};

export const regenerateApiClientSecret = async (token, clientId) => {
  try {
    const response = await clientApi.post(`/clients/${clientId}/regenerate_secret/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error regenerating API client secret:", error);
    throw error;
  }
};

export const deleteApiClient = async (token, clientId) => {
  try {
    await clientApi.delete(`/clients/${clientId}/`,
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return true;
  } catch (error) {
    console.error("Error deleting API client:", error);
    throw error;
  }
};