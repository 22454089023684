// src/config.js
const config = {
  apiUrl: process.env.NODE_ENV === 'production'
    ? 'https://dvakota.com/api'
    : 'http://127.0.0.1:8000/api',
  clientApiUrl: process.env.NODE_ENV === 'production'
      ? 'https://dvakota.com/client-api/v1'
      : 'http://127.0.0.1:8000/client-api/v1',
};

export default config;
